
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import Modal from '@/common/base/Modal.vue';

import IconAvoidMinimalPrice from '@/icons/other/AvoidMinimalPrice.vue';

import SubscriptionCard from '@/common/SubscriptionCard.vue';
import { SUBSCRIPTION_PLAN } from '@/subscription';
import { JobPricing, ListJobPricingWithAPI } from '@/interface/pricing.interface';

import { inactiveJobTypes } from '@/settings';
import requests from '@/requests';
import JobRatesSlot from './components/JobRatesSlot.vue';

type JobPricingKeys = (keyof JobPricing)[];

@Options({
  components: {
    Modal,
    IconAvoidMinimalPrice,
    SubscriptionCard,
    JobRatesSlot,
  },
  computed: {
    ...mapState(['profile']),
  },
  emits: {
    close: Boolean,
  },
})
export default class ModalJobRates extends Vue {
  public SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN;

  public pricing: JobPricing | null = null;

  public closeModal(): void {
    this.$store.commit('clearUserSpecialOptions', { phone_disconnect_on_match: false });
    this.$emit('close', true);
  }

  public selectAvoidPlan(plan: SUBSCRIPTION_PLAN): SUBSCRIPTION_PLAN {
    switch (plan) {
      case SUBSCRIPTION_PLAN.free:
        return SUBSCRIPTION_PLAN.standard;
      case SUBSCRIPTION_PLAN.standard:
        return SUBSCRIPTION_PLAN.premium;
      default:
        return SUBSCRIPTION_PLAN.premium;
    }
  }

  public parsePricing(pricing: JobPricing): void {
    const excludeKeys = ['api', ...inactiveJobTypes];

    const pricingKeys: JobPricingKeys = Object.keys(pricing) as JobPricingKeys;

    const newPricingList: JobPricing = {} as JobPricing;

    pricingKeys.forEach((p) => {
      if (!excludeKeys.some((e) => e === p)) { (newPricingList[p] as ListJobPricingWithAPI) = pricing[p]; }
    });

    this.pricing = newPricingList as JobPricing;
  }

  private changeShowModalLoader(status: boolean): void {
    this.$store.commit('changeShowModalLoader', status);
  }

  created(): void {
    const getUserId = () => {
      const session = localStorage.getItem('session');
      if (session) {
        const parsedSession = JSON.parse(session);
        return parsedSession.profile.id;
      }
      return '';
    };

    this.changeShowModalLoader(true);

    requests.billing.getJobPricing()
      .then((res) => { this.parsePricing(res.data); })
      .finally(() => { this.changeShowModalLoader(false); });

    const userId = getUserId();
    requests.billing.getUserSpecialOptions(userId)
      .then((res) => {
        // set state key hasSpecialOptions
        this.$store.commit('trackUserSpecialOptions', res.data);
      })
      .finally(() => { console.log('end of request for user special options'); });
  }
}
