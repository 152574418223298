
import { Options, Vue } from 'vue-class-component';
import { watch, WatchStopHandle } from 'vue';
import { mapState } from 'vuex';

import ModalJobRates from '@/modals/ModalJobRates.vue';
import ModalChangeSubscription from '@/modals/ChangeSubscription.vue';

import { ModalsState, ToggleModal } from '@/store/modules/modals';
import { SUBSCRIPTION_PLAN } from '@/subscription';

@Options({
  components: {
    ModalJobRates,
    ModalChangeSubscription,
  },
  computed: {
    ...mapState('modals', ['jobRate', 'changeSubscription']),
  },
})
export default class GlobalModal extends Vue {
  public accessibilityPlan = [SUBSCRIPTION_PLAN.free, SUBSCRIPTION_PLAN.standard, SUBSCRIPTION_PLAN.premium];

  public watchStopHandle!: WatchStopHandle;

  public open(modal: keyof ModalsState): void {
    this.$store.commit('modals/toggle', { modal, status: true } as ToggleModal);
  }

  public close(modal: keyof ModalsState): void {
    this.$store.commit('modals/toggle', { modal, status: false } as ToggleModal);
  }

  created(): void {
    this.watchStopHandle = watch(() => this.$route.path, () => { this.$store.commit('modals/reset'); });
  }

  unmounted(): void {
    this.watchStopHandle();
  }
}
