import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createBlock(_component_cropper, {
    ref: "cropper",
    src: _ctx.profile.profile_picture,
    stencilComponent: _ctx.CircleStencil,
    minWidth: 150,
    minHeight: 150,
    onChange: _ctx.handleCropperChange
  }, null, 8, ["src", "stencilComponent", "onChange"]))
}