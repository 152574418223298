
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { JOB_TYPE_TITLE } from '@/interface/job.interface';

import IconsSubscription from '@/icons/other/Subscription.vue';
import { SUBSCRIPTION_PLAN } from '@/subscription';
import { ListJobPricingWithoutAPI, MINIMUM_PRICE_TITLE, PRICING_TITLE } from '@/interface/pricing.interface';
import { SuffixPrice } from '@/common/JobOptions/jobOptions.interface';

@Options({
  components: {
    IconsSubscription,
  },
  computed: {
    ...mapState(['profile']),
    ...mapState(['specialOptions']),
  },
  props: {
    prices: Object,
    type: String,
  },
})
export default class ModalJobRatesSlot extends Vue {
  declare $props: {
    prices: ListJobPricingWithoutAPI;
    type: string;
  }

  public JOB_TYPE_TITLE = JOB_TYPE_TITLE;

  public PRICING_TITLE = PRICING_TITLE;

  public SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN;

  public MINIMUM_PRICE_TITLE = MINIMUM_PRICE_TITLE;

  public getPrice(key: string, price: number): string {
    if (this.$props.type === 'phone_disconnect') {
      if (this.$store.state.specialOptions.phone_disconnect_on_match) {
        // eslint-disable-next-line no-param-reassign
        key = 'ppm';
      }
    }

    const suffix: SuffixPrice = key === 'ppm' ? 'match' : 'record';

    return `${price}/${suffix}`;
  }

  // this here because if paste in template, my theme in vscode glitching((
  get typeMinimumPrice(): boolean {
    return typeof this.$props.prices.minimum_price !== 'object';
  }
}
