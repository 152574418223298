
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { shallowRef } from 'vue';

import {
  Cropper,
  CircleStencil,
  CropperResult,
} from 'vue-advanced-cropper';
import { CropperInstance } from '@/interface/other.interface';

@Options({
  components: {
    Cropper,
  },
  computed: {
    ...mapState(['profile']),
  },
  emits: {
    avatar: String,
  },
})
export default class CropperAvatar extends Vue {
  declare $refs: {
    cropper: CropperInstance;
  }

  public CircleStencil = shallowRef(CircleStencil);

  private watch: any;

  public handleCropperChange(res: CropperResult) {
    if (res.canvas) {
      const result = res.canvas.toDataURL();

      sessionStorage.setItem('avatar', result);

      this.$store.commit('update', 'avatar');
    }
  }

  private setCoordinates(): void {
    const coordinates = this.$store.state?.profile?.profile_picture_metadata;

    if (coordinates) this.$refs.cropper.setCoordinates(coordinates);
  }

  created(): void {
    this.watch = this.$store.watch((state) => state.profile, () => { this.setCoordinates(); });
  }

  mounted(): void {
    this.setCoordinates();
  }

  unmounted(): void {
    this.watch();
  }
}
