import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalChangeSubscription = _resolveComponent("ModalChangeSubscription")!
  const _component_ModalJobRates = _resolveComponent("ModalJobRates")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.changeSubscription)
      ? (_openBlock(), _createBlock(_component_ModalChangeSubscription, {
          key: 0,
          title: "Start using IMDatacenter",
          plans: _ctx.accessibilityPlan,
          showRates: "",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close('changeSubscription'))),
          onShowRates: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open('jobRate')))
        }, null, 8, ["plans"]))
      : _createCommentVNode("", true),
    (_ctx.jobRate)
      ? (_openBlock(), _createBlock(_component_ModalJobRates, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close('jobRate')))
        }))
      : _createCommentVNode("", true)
  ], 64))
}