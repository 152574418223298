
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {

  },
  props: {

  },
})
export default class ModalLoader extends Vue {
  mounted(): void {
    document.documentElement.classList.add('modal-loader-open');
  }

  unmounted(): void {
    document.documentElement.classList.remove('modal-loader-open');
  }
}
