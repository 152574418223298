
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import CropperAvatar from '@/common/CropperAvatar.vue';

import GlobalModal from '@/modals/GlobalModal.vue';

import ModalLoader from '@/modals/ModalLoader.vue';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';

@Options({
  components: {
    Header,
    Footer,
    ModalLoader,
    GlobalModal,
    CropperAvatar,
  },
  computed: {
    ...mapState(['showModalLoader', 'profile']),
  },
})
export default class PrivateWrapper extends Vue {}
