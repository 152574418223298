import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ModalLoader = _resolveComponent("ModalLoader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_GlobalModal = _resolveComponent("GlobalModal")!
  const _component_CropperAvatar = _resolveComponent("CropperAvatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    (_ctx.showModalLoader)
      ? (_openBlock(), _createBlock(_component_ModalLoader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.profile)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Footer),
    _createVNode(_component_GlobalModal),
    (_ctx.profile?.profile_picture)
      ? (_openBlock(), _createBlock(_component_CropperAvatar, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}